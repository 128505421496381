import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Select from "react-select";
import Modal from "../UI/Modal";
import {
  ContactStyles,
  ContactInfo,
  ContactSeparator,
  Button,
  Text,
  ContactForm,
  Fieldset,
  Label,
  Input,
  Telephone,
  LinkedIn,
  // ContactIcons,
} from "./Contact.styled";

// This setups the contact form
export default function Contact() {
  const [isFormFocused, setFormFocused] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");

  const [message, setMessage] = useState("");
  const [sinister, setSinister] = useState(null);
  const [damageType, setDamageType] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [isError, setIsError] = useState(false);

  // Sends the data by an URL
  const encode = (data) =>
    Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join("&");

  // Handle the submission of the form and the encoding.
  const handleSubmit = (event) => {
    event.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        name,
        email,
        telephone,
        sinister,
        damageType,
        contact_message: message,
      }),
    })
      .then(() => {
        setIsReady(true); // Set the form ready state
        alert("Solicitud de consulta enviada"); // Show the alert after the form is ready
      })
      .catch((error) => {
        console.error(error); // Log the error
        setIsError(true); // Set error state
        setIsReady(true); // Ensure the modal is shown even if there's an error
      });
  };

  // Toggles the form when it's focused, think it as a flag.
  const handleFormFocus = () => {
    setFormFocused((previousState) => !previousState);
  };

  // Resets the modal once the user closes it
  const resetModal = () => {
    setIsReady(false);
    setIsError(false);
  };

  // All types of damage are defined here.
  const damageOptions = {
    Hogar: [
      "Inundaciones",
      "Incendios",
      "Eléctrico",
      "Fugas de gas",
      "Robos o vandalismo",
      "Plagas",
      "Fenómenos atmosféricos",
      "Piscinas & Jacuzzis",
      "Obras cercanas",
      "Otros",
    ],
    Automovil: [
      "Colisiones",
      "Atropellos",
      "Robo Vehículo",
      "Fenómenos naturales",
      "Otros",
    ],
    Agrario: [
      "Granizo",
      "Heladas",
      "Inundaciones",
      "Sequía",
      "Incendios",
      "Ganadería",
      "Daño infraestructuras",
      "Otros",
    ],
    "Devolución de gastos hipotecarios": [
      "Seguro de vida",
      "Seguro de hogar",
      "Seguro de responsabilidad civil",
      "Seguro de desempleo",
      "Seguro de proteccion de pagos",
      "Gastos legales",
    ],
    Otros: ["Otros"],
  };

  // Renders the form and displays it
  const isFormDirty =
    email || name || message || sinister || damageType || telephone;
  return (
    <section style={{ marginBottom: "48px" }} id="contact">
      <ContactStyles
        className="container"
        isFormFocused={isFormFocused || isFormDirty}
      >
        {/* Contact information to the left just above the footer */}
        <ContactInfo>
          <h2>Contacto</h2>
          <ContactSeparator />
          <Text>
            Programe una reunión y permítanos ayudarlo. <br />{" "}
            <Telephone href="https://wa.me/34614140532">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="svg-inline--fa fa-phone-square-alt fa-w-14 fa-3x"
                viewBox="0 0 16 16"
              >
                <path
                  fill="var(--color__primary)"
                  d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"
                />
              </svg>{" "}
              +34 614 14 05 32
            </Telephone>
            <Telephone href="tel:34614140532">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="svg-inline--fa fa-phone-square-alt fa-w-14 fa-3x"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="var(--color__primary)"
                  d="M18.48 22.926l-1.193.658c-6.979 3.621-19.082-17.494-12.279-21.484l1.145-.637 3.714 6.467-1.139.632c-2.067 1.245 2.76 9.707 4.879 8.545l1.162-.642 3.711 6.461zm-9.808-22.926l-1.68.975 3.714 6.466 1.681-.975-3.715-6.466zm8.613 14.997l-1.68.975 3.714 6.467 1.681-.975-3.715-6.467z"
                />
              </svg>{" "}
              +34 614 14 05 32
            </Telephone>
            <LinkedIn
              href="https://www.linkedin.com/company/mataseguros/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="linkedin"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="svg-inline--fa fa-linkedin fa-w-14 fa-3x"
              >
                <path
                  fill="var(--color__primary)"
                  d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                  className=""
                />
              </svg>
              @mataseguros
            </LinkedIn>
            <LinkedIn
              href="https://www.youtube.com/mataseguros"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="youtube"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                className="svg-inline--fa fa-youtube fa-w-18 fa-3x"
              >
                <path
                  fill="var(--color__primary)"
                  d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                  className=""
                />
              </svg>
              Mataseguros
            </LinkedIn>
          </Text>
          {/* Social?? */}
          {/* <ContactIcons /> */}
        </ContactInfo>
        {/* Here ends the Contact information */}
        {/* Deals and renders the contact form */}
        <ContactForm isFormFocused={isFormFocused || isFormDirty}>
          <form
            netlify-honeypot="bot-field"
            netlify="true"
            name="contact"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <Fieldset>
              <Label htmlFor="firstName">Nombre</Label>
              <Input
                type="text"
                name="name"
                id="firstName"
                onFocus={() => setFormFocused(true)}
                onBlur={handleFormFocus}
                required
                onChange={(event) => setName(event.target.value)}
              />
            </Fieldset>
            {/* Email of the client */}
            <Fieldset>
              <Label htmlFor="email">Email</Label>
              <Input
                name="email"
                type="email"
                id="email"
                onFocus={() => setFormFocused(true)}
                onBlur={handleFormFocus}
                required
                onChange={(event) => setEmail(event.target.value)}
              />
            </Fieldset>
            {/* Telephone of the client */}
            <Fieldset>
              <Label htmlFor="email">Teléfono</Label>
              <Input
                name="telephone"
                type="telephone"
                id="telephone"
                onFocus={() => setFormFocused(true)}
                onBlur={handleFormFocus}
                required
                onChange={(event) => setTelephone(event.target.value)}
              />
            </Fieldset>
            {/* Asks the potential client their problems */}
            <Fieldset>
              <Label htmlFor="sinister">Tipo de incidencia</Label>
              <Select
                name="sinister"
                id="sinister"
                required
                placeholder="Tipo de incidencia"
                options={[
                  { value: "Hogar", label: "Hogar" },
                  { value: "Automovil", label: "Automovil" },
                  { value: "Agrario", label: "Agrario" },
                  {
                    value: "Devolución de gastos hipotecarios",
                    label: "Devolución de gastos hipotecarios",
                  },
                  { value: "Otros", label: "Otros" },
                ]}
                onChange={(value) => {
                  setSinister(value.label); // Pon el valor seleccionado
                  setDamageType(null); // Resetea el valor del daño específico
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "rgb(232, 237, 234)",
                    primary: "var(--color__primary)",
                  },
                })}
              />
            </Fieldset>
            {/* This is another dropdown menu that only appears when the the first one is selected. */}
            {sinister && (
              <Fieldset>
                <Label htmlFor="damageType">Tipo de Daño</Label>
                <Select
                  name="damageType"
                  id="damageType"
                  required
                  placeholder="Elige un tipo de daño..."
                  value={
                    damageType ? { value: damageType, label: damageType } : null
                  } // Bind to state
                  options={
                    damageOptions[sinister]?.map((damage) => ({
                      value: damage,
                      label: damage,
                    })) || []
                  } // Handle undefined case
                  onChange={(value) => setDamageType(value.value)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "rgb(232, 237, 234)",
                      primary: "var(--color__primary)",
                    },
                  })}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Label htmlFor="contact_message">Mensaje</Label>
              <textarea
                name="contact_message"
                rows="6"
                cols="50"
                onFocus={() => setFormFocused(true)}
                onBlur={handleFormFocus}
                onChange={(event) => setMessage(event.target.value)}
              />
            </Fieldset>
            <Fieldset>
              <Label htmlFor="terms" className="terms-label">
                <Input
                  type="checkbox"
                  className="custom-checkbox"
                  name="terms"
                  id="terms"
                  required
                />
                Acepto los&nbsp;{" "}
                <a
                  href="/terms"
                  target="_blank"
                  rel="noopener noreferrer" // Blue color for the link
                >
                  términos y condiciones
                </a>
              </Label>
            </Fieldset>
            <div data-netlify-captcha />

            <Button isFormFocused={isFormFocused || isFormDirty}>
              Mandar mensaje
            </Button>
          </form>
        </ContactForm>
      </ContactStyles>
      {isReady && <Modal onClose={resetModal} isError={isError} />}
    </section>
  );
}
